import { NetworkUrlOfChainId, EthRPCEndpoints, ChainType, SubGovTokenAddress, ThetaRPCEndpoints } from 'common/constants';
const host = 'https://tsub77529-explorer.thetatoken.org';
const hostApi = 'https://tsub77529-explorer-api.thetatoken.org';
const hostWs = 'https://tsub77529-explorer-ws.thetatoken.org';
const config = {
    restApi: {
        host: hostApi,
        port: 443
    },
    socketApi: {
        host: hostWs,
        port: 443
    },
    defaultThetaChainID: NetworkUrlOfChainId[host],
    ethRPCEndpoint: "https://eth-rpc-tsub77529.imaginereplay.com/rpc",
    thetaRPCEndPoint: "https://rpc-tsub77529.imaginereplay.com/rpc",
    chainInfo: {
        metachain: {
            host: "https://metachain-explorer.thetatoken.org"
        },
        mainchain: {
            name: 'Theta Main Chain',
            description: 'The Main Chain serves as the Metachain Hub and coordinates the Subchains.',
            host: 'https://explorer.thetatoken.org',
            hostApi: 'https://explorer-api.thetatoken.org',
            hostWs: 'https://explorer-ws.thetatoken.org',
            logoName: "theta",
            restApiPort: 443,
            socketApiPort: 443
        },
        subchains: [{
            name: 'Lavita AI Subchain',
            description: 'Lavita AI subchain, powers the first blockchain-AI based health information marketplace aiming to revolutionize the way 8 billion individuals around the world diagnose, analyze and treat health and genomic issues, leading to earlier intervention and improved outcome.',
            host: 'https://tsub360890-explorer.thetatoken.org',
            hostApi: 'https://tsub360890-explorer-api.thetatoken.org',
            hostWs: 'https://tsub360890-explorer-ws.thetatoken.org',
            logoName: "lavita",
            restApiPort: 443,
            socketApiPort: 443
        }, {
            name: 'POG Subchain',
            description: 'POGCHAIN, an advanced subchain platform redefining digital entertainment and gaming with a nostalgic twist of POG collectibles.',
            host: 'https://tsub9065-explorer.thetatoken.org',
            hostApi: 'https://tsub9065-explorer-api.thetatoken.org',
            hostWs: 'https://tsub9065-explorer-ws.thetatoken.org',
            logoName: "pog",
            restApiPort: 443,
            socketApiPort: 443
        }, {
            name: 'Passaways Subchain',
            description: 'Custom-built for the Passaways/PLASM ecosystem on Theta. Governed by PLASM.',
            host: 'https://tsub7734-explorer.thetatoken.org',
            hostApi: 'https://tsub7734-explorer-api.thetatoken.org',
            hostWs: 'https://tsub7734-explorer-ws.thetatoken.org',
            logoName: "passaways",
            restApiPort: 443,
            socketApiPort: 443
        }, {
            name: 'Grove Subchain',
            description: 'GROVE subchain powers the next-gen GroveWars Web3 gaming ecosystem and 3D Character Builder.',
            host: 'https://tsub47683-explorer.thetatoken.org',
            hostApi: 'https://tsub47683-explorer-api.thetatoken.org',
            hostWs: 'https://tsub47683-explorer-ws.thetatoken.org',
            logoName: "grove",
            restApiPort: 443,
            socketApiPort: 443
        }, {
            name: 'Replay Subchain',
            description: 'Replay Subchain, a subchain dedicated to the Replay, a video tracking and payments platform powered by Theta.',
            host: 'https://tsub77529-explorer.thetatoken.org',
            hostApi: 'https://tsub77529-explorer-api.thetatoken.org',
            hostWs: 'https://tsub77529-explorer-ws.thetatoken.org',
            logoName: "replay",
            restApiPort: 443,
            socketApiPort: 443
        }, {
            name: 'Bullit Subchain',
            description: 'Blockchain-based platform focused on robust data privacy and user control. Aimed at enhancing data security and accessibility for users worldwide.',
            host: 'https://tsub285548-explorer.thetatoken.org',
            hostApi: 'https://tsub285548-explorer-api.thetatoken.org',
            hostWs: 'https://tsub285548-explorer-ws.thetatoken.org',
            logoName: "bullit",
            restApiPort: 443,
            socketApiPort: 443
        }, {
            name: 'Space Junk Subchain',
            description: 'A community creation subchain powering the world of the workspace comedy, Space Junk.',
            host: 'https://tsub360889-explorer.thetatoken.org',
            hostApi: 'https://tsub360889-explorer-api.thetatoken.org',
            hostWs: 'https://tsub360889-explorer-ws.thetatoken.org',
            logoName: "toonstar",
            restApiPort: 443,
            socketApiPort: 443
        }, {
            name: 'Playground Subchain',
            description: 'Playground subchain, a sandbox environment for developers and users to experience the Theta Metachain technology.',
            host: 'https://tsub360888-explorer.thetatoken.org',
            hostApi: 'https://tsub360888-explorer-api.thetatoken.org',
            hostWs: 'https://tsub360888-explorer-ws.thetatoken.org',
            logoName: "playground",
            restApiPort: 443,
            socketApiPort: 443
        }]
    },
    chainName: 'Replay Subchain',
    chainType: ChainType.SUBCHAIN,
    subGovTokenAddress: "0x3Da3D8CDE7B12CD2CBb688E2655BcaCD8946399D",
    tokenMap: {},
    hasAbout: true,
    chainDescription: 'Replay Subchain, a subchain dedicated to the Replay, a video tracking and payments platform powered by Theta.',
    logoName: 'replay',
    links: {
        website: "https://imaginereplay.org",
        whitePaper: "https://assets-cdn.imaginereplay.com/docs/Imagine-Replay-Whitepaper-latest.pdf",
        cmc: "",
        ck: "",
        tg: "",
        twitter: "http://twitter.com/@imaginereplay",
        discord: "https://discord.gg/invite/imaginereplay-938899398908325889",
        medium: "https://medium.com/@imaginereplay"
    },
    tokenName: "rplay"
};
export default config;
